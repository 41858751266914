<template>
  <div>
    <h1 class="outline--text">Accounts</h1>
    <v-row>
      <v-col>
        <v-tabs>
          <v-tab :to="{ name: 'AccountListPage' }">Accounts</v-tab>
          <v-tab :to="{ name: 'AccountLogsPage' }">User logs</v-tab>
        </v-tabs>
      </v-col>
      <v-col class="text-end">
        <v-btn
          v-if="canManageUsers"
          small
          color="primary"
          @click="editUserDialog = true"
          rounded
          class="mt-3"
          >New user
        </v-btn>
        <v-btn
          v-if="canManage"
          small
          color="primary"
          @click="editItem(null)"
          rounded
          class="mt-3 ml-3"
          >New account
        </v-btn>
      </v-col>
    </v-row>
    <account-search></account-search>
    <v-row>
      <v-col class="outline--text mt-9 pl-4 text-caption">
        <span v-if="!isLoading && this.totalCount > 0">
          <p v-if="this.totalCount > this.currentPage * this.itemsPerPage">
            Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
              this.currentPage * this.itemsPerPage
            }}
            of {{ this.totalCount }} items
          </p>
          <p v-else>
            Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
              this.totalCount
            }}
            of {{ this.totalCount }} items
          </p>
        </span>
      </v-col>
      <v-col style="">
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-pagination
            v-if="showPages && pageCount > 1"
            class="my-3"
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="7"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
          <div class="d-flex mx-10">
            <v-select
              v-model="pagination.rowPerPage"
              item-text="label"
              item-value="attribute"
              :items="countPerPage"
              label="Show items"
              style="max-width: 80px"
            >
            </v-select>
          </div>
          <v-select
            item-text="label"
            item-value="attribute"
            :items="sortAttributes"
            label="sortBy"
            v-model="sort.sortBy"
            style="max-width: 180px"
          ></v-select>
          <span v-if="sort.sortBy" class="mt-5 ml-2">
            <v-icon v-if="sort.desc" title="sortAsc" @click="sort.desc = false">
              mdi-sort-descending
            </v-icon>
            <v-icon v-else title="sortDesc" @click="sort.desc = true">
              mdi-sort-ascending
            </v-icon>
          </span>
        </div>
      </v-col>
    </v-row>
    <div>
      <!--    Data table     -->
      <v-data-iterator
        hide-default-footer
        disable-filtering
        disable-pagination
        disable-sort
        :items="accounts"
        :no-data-text="isLoading ? 'Loading items...' : 'No data'"
        style="overflow-x: auto; overflow-y: hidden; white-space: nowrap;"
      >
        <template v-slot:header>
          <!--    Table header begin -->
            <div
              v-if="totalCount > 0 || isLoading"
              class="d-sm-none d-md-none d-lg-block px-4 py-0 pb-0"
              style="max-width: 1500px;"
            >
              <v-row class="grey--text text-caption flex-nowrap" style="margin-top: 5px; min-width: 1580px; width: 98%;">
                <v-col cols="2" class="align-self-end">Name & Balance</v-col>
                <v-col cols="1" class="align-self-end" style="max-width: 100px"
                  >Users</v-col
                >
                <v-col cols="1" class="align-self-end" style="max-width: 100px"
                  >Projects</v-col
                >
                <v-col cols="1" class="align-self-end">Admin</v-col>
                <v-col cols="2" class="align-self-end">Email</v-col>
                <v-col cols="2" class="align-self-end" style="max-width: 150px">Created</v-col>
                <v-col cols="1" class="align-self-end" style="max-width: 100px"
                  >Curr package</v-col
                >
                <v-col cols="1" class="align-self-end" style="max-width: 100px"
                  >Next package</v-col
                >
                <v-col cols="2" class="align-self-end" style="max-width: 150px">Last payment</v-col>
                <v-col cols="1" class="align-self-end" style="max-width: 50px">Amount</v-col>
                <v-col cols="2" class="align-self-end text-end pr-4"
                  >Last logged
                </v-col>
              </v-row>
            </div>

            <!-- Data loader -->
            <v-progress-linear
              v-if="isLoading"
              indeterminate
              color="primary"
              class="my-3"
              style="width:105%"
            ></v-progress-linear>
          <!--    Table header end -->
        </template>
        <template v-slot:default="props">
            <account-item
                v-for="(item, i) in props.items"
                :key="i"
                :account="item"
            ></account-item>
        </template>
      </v-data-iterator>
    </div>
    <v-dialog v-model="editDialog" max-width="450px">
      <account-form
        v-if="editDialog"
        @close="closeEditDialog"
        @submitted="addAccount"
      ></account-form>
    </v-dialog>
    <v-dialog v-model="editUserDialog" max-width="450px">
      <user-form
        v-if="editUserDialog"
        :user="null"
        @close="editUserDialog = false"
        @submitted="editUserDialog = false"
      ></user-form>
    </v-dialog>
  </div>
</template>

<script>
import AccountSearch from "./account/list/AccountSearch";
import AccountItem from "@/views/account/list/AccountItem";
import AccountForm from "@/views/account/item/AccountForm";

import UserForm from "@/views/account/item/UserForm";

import { mapGetters, mapState } from "vuex";

import BaseListPage from "./base/BaseListPage";

import {
  defaultFilters,
  emptyFilters,
  multipleFilters,
} from "@/store/modules/account/state";
import {
  FETCH_ACCOUNT,
  FETCH_ACCOUNTS,
  SET_FILTERS,
} from "@/store/modules/account/actions";
import acl from "@/mixin/acl";
import {
  RESOURCE_ACCOUNTS_MANAGEMENT,
  RESOURCE_USERS_MANAGEMENT,
} from "@/store/modules/admin/getters";

export default {
  name: "AccountPage",
  mixins: [acl],
  extends: BaseListPage,
  components: { AccountSearch, AccountItem, AccountForm, UserForm },
  props: {
    limit: {
      required: false,
    },
  },
  data() {
    return {
      defaultFilters,
      emptyFilters,
      multipleFilters,
      showPages: false,
      isLoading: false,
      selectedItem: null,
      editDialog: false,
      editUserDialog: false,
      newAddedAccounts: [],
      countPerPage: [
        {
          attribute: 20,
          label: "20",
        },
        {
          attribute: 50,
          label: "50",
        },
        {
          attribute: 100,
          label: "100",
        },
        {
          attribute: 5000,
          label: "All",
        },
      ],
      sortAttributes: [
        {
          attribute: "createdAt",
          label: "Created at",
        },
        {
          attribute: "lastPayment",
          label: "Last payment",
        },
        {
          attribute: "amount",
          label: "Payment amount",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("account", [
      "accountList",
      "totalCount",
      "currentPage",
      "pageCount",
      "offset",
      "formattedFilters",
    ]),
    ...mapState("account", ["sort", "pagination", "filters"]),
    canManage: function () {
      return this.hasAccess([RESOURCE_ACCOUNTS_MANAGEMENT]);
    },
    canManageUsers: function () {
      return this.hasAccess([RESOURCE_USERS_MANAGEMENT]);
    },
    itemsPerPage: function () {
      return this.pagination.rowPerPage;
    },
    accounts: function () {
      return this.newAddedAccounts.concat(this.accountList);
    },
  },
  watch: {
    "pagination.rowPerPage": function () {
      this.pagination.page = 1;
    },
  },
  methods: {
    editItem: function (item) {
      this.selectedItem = item;
      this.editDialog = true;
    },
    closeEditDialog: function () {
      this.selectedItem = null;
      this.editDialog = false;
    },
    applyFilters: function (payload) {
      return this.$store.dispatch(`account/${SET_FILTERS}`, payload.data);
    },
    fetchItems: function () {
      const vm = this;
      vm.isLoading = true;
      vm.$store.dispatch(`account/${FETCH_ACCOUNTS}`).then(
        () => {
          vm.isLoading = false;
          vm.showPages = true;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    addAccount(params) {
      this.isLoading = true;
      this.$store
        .dispatch(`account/${FETCH_ACCOUNT}`, {
          accountId: params.id,
        })
        .then(
          (response) => {
            const { data } = response;
            this.newAddedAccounts.push(data);
            this.isLoading = false;
          },
          (error) => {
            console.log(error);
          }
        );
      this.closeEditDialog();
    },
  },
  mounted() {
    this.applyRouteParams();
    this.fetchItems();
  },
};
</script>
