<template>
  <div>
    <h1>
      <entity-update-task-add-dialog
        v-if="canManage"
        :projectId="this.projectId"
      >
      </entity-update-task-add-dialog>
      <project-update-task-add-dialog
        v-if="canManage"
        :projectId="this.projectId"
      >
      </project-update-task-add-dialog>
    </h1>
    <project-task-search class="pt-5"></project-task-search>
    <v-row>
      <v-col class="outline--text mt-7" style="max-width: 700px">
        <div>
          <span v-if="!isLoading && this.totalCount > 0">
            <p v-if="this.totalCount >= this.currentPage * this.itemsPerPage">
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.currentPage * this.itemsPerPage
              }}
              of {{ this.totalCount }} items
            </p>
            <p v-else>
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.totalCount
              }}
              of {{ this.totalCount }} items
            </p>
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-spacer></v-spacer>
          <v-pagination
            v-if="showPages && pageCount > 1"
            class="my-3"
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="7"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
          <div class="d-flex mx-10">
            <v-select
              v-model="pagination.rowPerPage"
              item-text="label"
              item-value="attribute"
              :items="countPerPage"
              label="Show items"
              style="max-width: 80px"
            >
            </v-select>
          </div>
          <div class="d-flex">
            <v-select
              item-text="label"
              item-value="attribute"
              :items="sortAttributes"
              label="sortBy"
              v-model="sort.sortBy"
              style="max-width: 180px"
            ></v-select>
            <span v-if="sort.sortBy" class="mt-5 ml-2">
              <v-icon
                v-if="sort.desc"
                title="sortAsc"
                @click="sort.desc = false"
              >
                mdi-sort-descending
              </v-icon>
              <v-icon v-else title="sortDesc" @click="sort.desc = true">
                mdi-sort-ascending
              </v-icon>
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="articles"
      :items-per-page="5"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
      :item-class="itemClass"
    >
      <template v-slot:item.status="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-if="item.status === 'processing'"
              v-bind="attrs"
              v-on="on"
              class="purple--text"
              >{{ item.status }}</span
            >
            <span
              v-else-if="item.status === 'completed'"
              v-bind="attrs"
              v-on="on"
              class="green--text"
              >{{ item.status }}</span
            >
            <span
              v-else-if="item.status === 'new'"
              v-bind="attrs"
              v-on="on"
              class="blue--text"
              >{{ item.status }}</span
            >
            <span v-else class="red--text" v-bind="attrs" v-on="on">{{
              item.status
            }}</span>
          </template>
          <span v-if="item.stats">{{ JSON.stringify(item.stats) }}</span>
          <span v-else>{{ item.status }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.sourceTypeList="{ item }">
        <img
          v-if="item.sourceTypeList.includes('media')"
          class="mt-1 mr-1"
          :src="require('@/assets/newspaper.svg')"
          alt=""
        />
        <img
          v-if="item.sourceTypeList.includes('facebook')"
          class="mt-1 mr-1"
          :src="require('@/assets/project_type_fb.svg')"
          alt=""
        />
        <img
          v-if="item.sourceTypeList.includes('twitter')"
          class="mt-1 mr-1"
          :src="require('@/assets/project_type_x.svg')"
          alt=""
        />
        <img
          v-if="item.sourceTypeList.includes('youtube')"
          class="mt-1 mr-1"
          :src="require('@/assets/project_type_yt.svg')"
          alt=""
        />
        <img
          v-if="item.sourceTypeList.includes('telegram')"
          class="mt-1 mr-1"
          :src="require('@/assets/telegram.svg')"
          alt=""
        />
        <img
          v-if="item.sourceTypeList.includes('linkedin')"
          class="mt-1 mr-1"
          :src="require('@/assets/linkedin.svg')"
          alt=""
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import {
  FETCH_PROJECT_TASKS,
  SET_FILTERS,
} from "@/store/modules/projectTasks/actions";

import BaseListPage from "./base/BaseListPage";
import {
  defaultFilters,
  emptyFilters,
  multipleFilters,
} from "@/store/modules/projectTasks/state";
import ProjectTaskSearch from "@/views/projectUpdateTask/list/ProjectTasksSearch.vue";
import EntityUpdateTaskAddDialog from "@/views/projectUpdateTask/item/EntityUpdateTaskAddDialog";
import ProjectUpdateTaskAddDialog from "@/views/projectUpdateTask/item/ProjectUpdateTaskAddDialog";
import { RESOURCE_PROJECTS_MANAGEMENT } from "@/store/modules/admin/getters";
import acl from "@/mixin/acl";
import { EventBus } from "@/utils";
import { NEW_CREATED_TASK } from "@/utils/events";

export default {
  name: "ProjectTasksPage",
  extends: BaseListPage,
  mixins: [acl],
  components: {
    ProjectTaskSearch,
    ProjectUpdateTaskAddDialog,
    EntityUpdateTaskAddDialog,
  },
  props: {
    projectId: {
      required: false,
    },
  },
  data() {
    return {
      defaultFilters,
      emptyFilters,
      multipleFilters,
      showPages: false,
      isLoading: false,
      countPerPage: [
        {
          attribute: 5,
          label: "5",
        },
        {
          attribute: 20,
          label: "20",
        },
        {
          attribute: 50,
          label: "50",
        },
        {
          attribute: 100,
          label: "100",
        },
        {
          attribute: 5000,
          label: "All",
        },
      ],
      headers: [
        {
          text: "Project Id",
          value: "projectId",
          width: "100",
        },
        {
          text: "Action Type",
          value: "actionTypeFormatted",
          width: "175",
        },
        {
          text: "Status",
          value: "status",
          width: "150",
        },
        {
          text: "Start",
          value: "start",
          width: "200",
        },
        {
          text: "Platforms",
          value: "sourceTypeList",
          width: "250",
        },
        {
          text: "Created",
          value: "createdAt",
          width: "200",
        },
        {
          text: "Completed",
          value: "completedAt",
          width: "200",
        },
      ],
      sortAttributes: [
        {
          attribute: "createdAt",
          label: "Created",
        },
        {
          attribute: "completedAt",
          label: "Completed",
        },
        {
          attribute: "status",
          label: "Status",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("projectTasks", [
      "items",
      "totalCount",
      "currentPage",
      "pageCount",
      "offset",
      "formattedFilters",
    ]),
    ...mapState("projectTasks", ["sort", "pagination", "filters"]),
    canManage: function () {
      return this.hasAccess([RESOURCE_PROJECTS_MANAGEMENT]);
    },
    itemsPerPage: function () {
      return this.pagination.rowPerPage;
    },
    articles: function () {
      if (this.isLoading) {
        return [];
      }
      return this.items;
    },
  },
  watch: {
    "pagination.rowPerPage": function () {
      this.pagination.page = 1;
    },
  },
  methods: {
    applyFilters: function (payload) {
      return this.$store.dispatch(`projectTasks/${SET_FILTERS}`, payload.data);
    },
    fetchItems: function () {
      const vm = this;
      vm.isLoading = true;
      vm.$store
        .dispatch(`projectTasks/${FETCH_PROJECT_TASKS}`, this.projectId)
        .then(
          () => {
            vm.isLoading = false;
            vm.showPages = true;
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
  mounted() {
    this.applyRouteParams();
    this.fetchItems();
  },
  created() {
    EventBus.$on(NEW_CREATED_TASK, () => {
      this.fetchItems();
    });
  },
};
</script>
